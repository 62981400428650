import React, { useEffect } from 'react';

import Seo from '../components/seo';
import '../components/styles/error.css';

const NotFoundPage = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (typeof window === 'undefined') {
        window.location.href = '/';
      }
    }, 5_000);
    return () => {
      clearInterval(timeout);
    };
  }, []);
  return (
    <>
      <Seo />
      <div id='bkgError'>
        <div class='container center'>
          <div id='erreur'>
            <h1 class='hypnoseErreur'>
              <b>404</b>
              <br />
              La page n'existe pas ou plus !
            </h1>
            <br />
            <h2 class='hypnoseErreur'><a href='/'>page d'accueil</a></h2>
          </div>
        </div>
      </div>
    </>
  )
  ;
};

export default NotFoundPage;
